import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { EnrollmentConflict } from '@/__generated__/globalTypes';
import { EventNames, LoginLocation, TEXTS } from '@/constants';
import { useEventLogger } from '@/utilities/analyticsHelper';

interface HelpTextProps {
  variant: 'secondary' | 'primary';
  enrollmentConflict: Exclude<EnrollmentConflict, EnrollmentConflict.NO_CONFLICT> | null;
}

export const HelpText = ({ variant, enrollmentConflict }: HelpTextProps) => {
  const logger = useEventLogger();

  const handleLogin = () => {
    logger({
      name: EventNames.LOGIN,
      data: {
        login_location: LoginLocation.WELCOME_PAGE_EMAIL_ERROR,
      },
    });
    window.location.href = `/vis/auth/login`;
  };

  if (enrollmentConflict === null) {
    return null;
  }

  return (
    <Typography
      component="span"
      variant="info1"
      sx={[
        variant === 'primary'
          ? (theme) => ({
              '&.MuiFormHelperText-root': {
                color: theme.palette.error.main,
              },
              '&.Mui-error': {
                color: theme.palette.error.main,
              },
            })
          : (theme) => ({
              '&.MuiFormHelperText-root': {
                color: theme.palette.care.white,
              },
              '&.Mui-error': {
                color: theme.palette.care.red[400],
              },
            }),
      ]}>
      {TEXTS.ERRORS[enrollmentConflict!]}{' '}
      {enrollmentConflict === EnrollmentConflict.EXISTING_WPS_ACCOUNT && (
        <Link
          component="button"
          onClick={handleLogin}
          sx={[
            {
              fontWeight: 'bold',
              pl: 0.5,
              display: 'contents',
            },
            variant === 'primary'
              ? (theme) => ({
                  color: theme.palette.text.link,
                  '&:hover,&:focus,&:focus:active': {
                    color: theme.palette.text.link,
                  },
                })
              : (theme) => ({
                  color: theme.palette.care.white,
                  '&:hover,&:focus,&:focus:active': {
                    color: theme.palette.care.white,
                  },
                }),
          ]}>
          Log in?
        </Link>
      )}
      {enrollmentConflict === EnrollmentConflict.MEMBER_EXISTS_NO_PREM_ACCESS && (
        <Box sx={{ pt: 1 }}>
          Need additional support? Visit&nbsp;
          <Link
            href="https://help.care.com"
            variant="link4"
            sx={[
              {
                fontWeight: 'bold',
              },
              variant === 'primary'
                ? (theme) => ({
                    color: theme.palette.text.link,
                    '&:hover,&:focus,&:focus:active': {
                      color: theme.palette.text.link,
                    },
                  })
                : (theme) => ({
                    color: theme.palette.care.white,
                    '&:hover,&:focus,&:focus:active': {
                      color: theme.palette.care.white,
                    },
                  }),
            ]}>
            help.care.com
          </Link>
        </Box>
      )}
    </Typography>
  );
};
