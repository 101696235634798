import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as yup from 'yup';
import { ENTERPRISE_ROUTES, EventNames, FEATURE_FLAGS, TEXTS, memberEnrollment } from '@/constants';
import { DirectEnrollment } from '@/types/directEnrollment';
import { useFlag } from '@/context/FeatureFlagsContext';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import useDirectEnrollmentData from '@/components/hooks/useDirectEnrollmentData';
import { ENTERPRISE_CHECK_ENROLLMENT_CONFLICT_BY_EMAIL } from '../request/GQL';
import { CompanySearchSelection } from '../pages/WelcomePage/components/CompanySearchInput/CompanySearchInput';
import { EnrollmentConflict } from '@/__generated__/globalTypes';
import {
  checkEnrollmentConflictByEmail,
  checkEnrollmentConflictByEmailVariables,
} from '@/__generated__/checkEnrollmentConflictByEmail';

interface ValidationSchema {
  email: yup.StringSchema<string, object>;
  groupName: yup.StringSchema<string, object>;
}

export const validationSchema: yup.ObjectSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  groupName: yup.string().required('Employer name is required'),
} as ValidationSchema);

const useCompanySearchForm = (logger: AmplitudeLogger) => {
  const router = useRouter();
  const { fetch, update } = useDirectEnrollmentData();
  const excludeClients = useFlag(FEATURE_FLAGS.ENT_REDIRECT_LD_FLAG.key);
  const lifeCareTransitionD2EClients = useFlag(
    FEATURE_FLAGS.ENT_LIFECARE_TRANSITION_D2E_CLIENT_FLAG.key
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [enrollmentConflict, setEnrollmentConflict] = useState<Exclude<
    EnrollmentConflict,
    EnrollmentConflict.NO_CONFLICT
  > | null>(null);
  const [ssoConnectionPath, setSSOConnectionPath] = useState<string | null>('');
  const [apolloError, setApolloError] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<CompanySearchSelection | null>(null);
  const [companySearchError, setCompanySearchError] = useState<string | null>(null);

  const [checkEnrollmentConflict, { loading: loadingCheckEnrollmentConflict }] = useLazyQuery<
    checkEnrollmentConflictByEmail,
    checkEnrollmentConflictByEmailVariables
  >(ENTERPRISE_CHECK_ENROLLMENT_CONFLICT_BY_EMAIL, {
    context: { skipAuth: true },
    onCompleted: (result) => {
      const { enterpriseCheckEnrollmentConflictByEmail } = result;
      if (
        enterpriseCheckEnrollmentConflictByEmail.__typename !==
        'EnterpriseCheckEnrollmentConflictByEmailSuccess'
      ) {
        setApolloError(enterpriseCheckEnrollmentConflictByEmail.knownErrors[0].message);
        return;
      }

      const { outcome } = enterpriseCheckEnrollmentConflictByEmail;

      if (outcome === EnrollmentConflict.NO_CONFLICT) {
        if (ssoConnectionPath) {
          window.location.href = ssoConnectionPath;
        } else if (
          Boolean(lifeCareTransitionD2EClients) &&
          storageValue.groupShortName in lifeCareTransitionD2EClients
        ) {
          router.push(
            `${memberEnrollment}${ENTERPRISE_ROUTES.LIFECARE_AUTHENTICATION}?groupShortName=${storageValue.groupShortName}`
          );
        } else {
          router.push(`${memberEnrollment}${ENTERPRISE_ROUTES.PERSONAL_INFO}`);
        }

        return;
      }

      setEnrollmentConflict(outcome);
      logger({
        name: EventNames.ERROR_VIEWED,
        data: {
          message: TEXTS.ERRORS[outcome],
          step_name: 'Welcome',
          step_number: '0',
          wps_group: selectedGroup?.shortName,
        },
      });

      setIsSubmitting(false);
    },
    onError: () => {
      setIsSubmitting(false);
      setApolloError(TEXTS.ERRORS.UNEXPECTED_ERROR);
    },
  });

  const storageValue = fetch();
  const companySearchPreFillName =
    (router?.query?.groupName as string) || storageValue?.groupDisplayName || '';
  const shouldAutoFocusEmail = Boolean(companySearchPreFillName) && !storageValue.email;

  const handleFormSubmit = async (values: Partial<DirectEnrollment>) => {
    if (companySearchError) {
      return;
    }

    setIsSubmitting(true);
    const { email } = values;

    update({
      groupShortName: selectedGroup?.shortName,
      groupDisplayName: selectedGroup?.name,
      email,
    });

    if (Boolean(excludeClients) && selectedGroup?.shortName! in excludeClients) {
      window.location.href = `${window.location.protocol}//${window.location.host}/${selectedGroup?.shortName}`;
      return;
    }

    if (email && selectedGroup?.shortName) {
      checkEnrollmentConflict({
        variables: {
          input: {
            email,
            groupShortName: selectedGroup?.shortName,
          },
        },
      });
    }
  };

  const handleSelection = async (careGroupSuggestion: CompanySearchSelection) => {
    setCompanySearchError(null);
    setSelectedGroup(careGroupSuggestion);

    if (careGroupSuggestion.name) {
      // update groupName value to use capitalization from selection
      formik.setFieldValue('groupName', careGroupSuggestion.name);
    }
  };

  const formik = useFormik<{
    groupName: string;
    email: string;
  }>({
    initialValues: {
      groupName: companySearchPreFillName,
      email: storageValue.email,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  return {
    formik,
    enrollmentConflict,
    companySearchPreFillName,
    shouldAutoFocusEmail,
    apolloError,
    loading: loadingCheckEnrollmentConflict,
    isSubmitting,
    companySearchError,
    setSSOConnectionPath,
    handleSelection,
    setCompanySearchError,
  };
};

export default useCompanySearchForm;
