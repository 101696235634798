import React, { FC, useRef } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import { ErrorNotification } from '@care/enterprise-components';
import useCompanySearchForm from '@/components/hooks/useCompanySearchForm';
import CompanySearchInput, {
  CompanySearchSelection,
} from '@/components/pages/WelcomePage/components/CompanySearchInput/CompanySearchInput';
import { EventNames } from '@/constants';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import TermsOfUse from '@/components/shared/TermsOfUse';
import FormikInlineTextField from '@/components/shared/blocks/FormikInlineTextField';
import { SxClassProps } from '@/types/global';
import { HelpText } from './HelpText';

const CTA_TEXT = 'Join now';

const classes: SxClassProps = {
  fields: {
    marginBottom: 1,

    '& > .input-container': {
      paddingTop: 0,
      paddingBottom: 0,
    },

    '& .MuiFormControl-root': {
      padding: 0,
      marginTop: 2,
    },
  },
  buttonContainer: {
    textAlign: 'center',
  },
  helperTextSecondary: (theme) => ({
    '&.MuiFormHelperText-root': {
      color: theme.palette.care.white,
    },
    '&.Mui-error': {
      color: theme.palette.care.red[400],
    },
  }),
  helperText: (theme) => ({
    '&.MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  }),
  cta: {
    width: '100%',
    maxWidth: { sm: '327px' },
  },
  ctaSecondary: {
    width: '100%',
    maxWidth: '233px',
  },
};

interface EnrollmentFormProps {
  id: string;
  logger: AmplitudeLogger;
  variant?: 'primary' | 'secondary';
  disableAutoFocus?: boolean;
  ctaTextLocation: string;
}

const EnrollmentForm: FC<EnrollmentFormProps> = ({
  id,
  logger,
  variant = 'primary',
  disableAutoFocus,
  ctaTextLocation,
}) => {
  const emailRef = useRef<HTMLInputElement>();

  const headingMargin = variant === 'primary' ? 3 : 4;
  const headingMarginMd = variant === 'primary' ? 2 : 5;

  const buttonContainerClass = variant === 'primary' ? undefined : classes.buttonContainer;
  const buttonClass = variant === 'primary' ? classes.cta : classes.ctaSecondary;

  const {
    formik,
    enrollmentConflict,
    apolloError,
    loading,
    companySearchPreFillName,
    shouldAutoFocusEmail,
    companySearchError,
    setCompanySearchError,
    handleSelection,
    setSSOConnectionPath,
  } = useCompanySearchForm(logger);

  const handleCtaClick = () => {
    logger({
      name: EventNames.CTA_INTERACTED,
      data: {
        cta_location: ctaTextLocation,
        cta_text: CTA_TEXT,
      },
    });

    if (Object.keys(formik.errors).length) {
      logger({
        name: EventNames.ERROR_VIEWED,
        data: {
          error_type: 'Form Validation Error',
          message: Object.values(formik.errors)[0],
        },
      });
    }
  };

  const handleCompanySelect = (selection: CompanySearchSelection, isPreFetch?: boolean) => {
    handleSelection(selection);

    // focus on email input
    if (selection.name && !isPreFetch) {
      emailRef.current?.focus();
    }
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Form id={id}>
          <ErrorNotification text={apolloError} />

          <Box component="div" sx={{ marginBottom: { xs: headingMargin, md: headingMarginMd } }}>
            <Grid container sx={classes.fields} spacing={2} justifyContent="center">
              <Grid
                item
                xs={12}
                sm={variant === 'primary' ? 6 : 10}
                md={6}
                className="input-container">
                <CompanySearchInput
                  inputProps={{
                    id: `${id}_company`,
                  }}
                  helperTextProps={{
                    sx: variant === 'primary' ? classes.helperText : classes.helperTextSecondary,
                  }}
                  onSelection={handleCompanySelect}
                  onError={setCompanySearchError}
                  setSSOConnectionPath={setSSOConnectionPath}
                  autoFocus={
                    !disableAutoFocus && !companySearchPreFillName && !shouldAutoFocusEmail
                  }
                  prefillName={companySearchPreFillName}
                  errorMessage={companySearchError}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={variant === 'primary' ? 6 : 10}
                md={6}
                className="input-container">
                <FormikInlineTextField
                  inputProps={{ ref: emailRef }}
                  id={`${id}_email`}
                  name="email"
                  label="Email"
                  autoFocus={!disableAutoFocus && shouldAutoFocusEmail}
                  helperText={
                    <HelpText variant={variant} enrollmentConflict={enrollmentConflict} />
                  }
                  FormHelperTextProps={{
                    sx: variant === 'primary' ? classes.helperText : classes.helperTextSecondary,
                  }}
                />
              </Grid>
            </Grid>

            {variant === 'secondary' && (
              <TermsOfUse amplitudeLogger={logger} buttonCaption={CTA_TEXT} variant="universal" />
            )}
          </Box>

          <Box component="div" sx={buttonContainerClass}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleCtaClick}
              disabled={loading}
              sx={buttonClass}>
              {CTA_TEXT}
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default EnrollmentForm;
