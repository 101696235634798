import React, { FC } from 'react';
import { Box, Grid, List, ListItem, Typography } from '@mui/material';
import { Benefit } from '../../types/types';
import { BENEFITS_COPY } from '../../data/text';
import { SxClassProps } from '@/types/global';

const { DISCLAIMER } = BENEFITS_COPY;

const classes: SxClassProps = {
  posterImage: {
    width: '100%',
    maxWidth: '208px',
    height: 'auto',
    display: 'inline-block',
    margin: 0,
  },
};

interface BenefitDetailsProps {
  benefit: Benefit;
  renderDesktopView?: boolean;
}

const BenefitDetails: FC<BenefitDetailsProps> = ({ benefit, renderDesktopView }) => {
  return (
    <Box
      component="article"
      data-testid="BenefitDetails"
      sx={{
        paddingTop: { xs: 2, md: 6 },
        paddingBottom: { xs: 4, md: 0 },
        paddingLeft: { md: 12 },
        paddingRight: { md: 12 },
      }}>
      <Grid container>
        <Grid item xs={12} md={8} lg={7}>
          {renderDesktopView && (
            <>
              <Typography
                variant="body3"
                component="h4"
                sx={(theme) => ({
                  color: theme.palette.care.grey[600],
                  marginBottom: 1,
                  lineHeight: '20px',
                })}>
                <strong>{benefit.subHeadline}</strong>
              </Typography>
              <Typography
                variant="serifHeadline2"
                component="h3"
                sx={{
                  mb: 3,
                }}>
                {benefit.headline}
              </Typography>
            </>
          )}

          <Typography
            variant="body2"
            sx={{
              mb: 3,
            }}>
            {benefit.description}
          </Typography>

          <List
            sx={{
              mt: 0,
              mb: { xs: 4, md: 6 },
              pl: { xs: 4, md: 3 },
            }}>
            {benefit.valueProps.map((valueProp) => (
              <ListItem
                key={valueProp}
                sx={{
                  mb: 1.5,
                  display: 'list-item',
                  listStyleType: 'disc',
                  p: 0,
                }}>
                <Typography variant="body2">{valueProp}</Typography>
              </ListItem>
            ))}
          </List>

          <Grid container>
            <Grid item xs={12} md={9}>
              {renderDesktopView && (
                <Typography variant="body2" color="secondary">
                  {DISCLAIMER}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} lg={5}>
          <Box
            component="div"
            sx={{
              textAlign: { xs: 'center', md: 'right' },
              px: 2,
            }}>
            <Box
              component="figure"
              className="poster-animator"
              sx={{
                width: '100%',
                maxWidth: '208px',
                height: 'auto',
                display: 'inline-block',
                margin: 0,
              }}>
              <Box
                component="img"
                src={benefit.poster.src}
                alt={benefit.poster.alt}
                sx={classes.posterImage}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BenefitDetails;
