import { Box, Container, Typography } from '@mui/material';
import React, { FC } from 'react';
import ReactPlayer from 'react-player';
import { theme as careTheme } from '@care/material-ui-theme';
import useResponsive from '@/components/hooks/useResponsive';
import { TRUSTED_VIDEO_URL } from '@/constants';
// import { EMPLOYER_LOGOS } from '../../data/employerLogos'; // ENT-7404 - Temporarily removing logos
import IconPlayVideo from '../IconPlayVideo/index';
import WaveDivider, { POSITION } from '../WaveDivider/WaveDivider';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  wrapper: (theme) => ({
    backgroundColor: theme.palette.care.blue[100],
  }),
  container: {
    paddingTop: 8,
    paddingBottom: { xs: 10, md: 13 },
  },
  heading: (theme) => ({
    textAlign: 'center',
    marginBottom: 6, // ENT-7404 - Set to 2 when adding logos back in
    fontSize: { md: theme.typography.serifDisplay3.fontSize },
    lineHeight: { md: theme.typography.serifDisplay3.lineHeight },
  }),
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: { xs: 7, md: 8 },
  },
  logoImage: {
    margin: 2,
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  videoPlayer: (theme) => ({
    background: theme.palette.care.grey[500],
    display: 'inline-block',
    borderRadius: '16px',
    overflow: 'hidden',
    width: { xs: '336px', md: '629px' },
    height: { xs: '190px', md: '355px' },
  }),
};

const TrustedSection: FC = () => {
  const { isMobile, isTablet } = useResponsive();
  return (
    <Box component="section" data-testid="TrustedSection" sx={classes.wrapper}>
      <Container maxWidth="lg" sx={classes.container}>
        <Typography variant="serifHeadline3" component="h2" sx={classes.heading}>
          Trusted by employers and employees around the globe
        </Typography>

        {/* ENT-7404 - Temporarily removing logos */}
        {/* <Box component="div" sx={classes.logoContainer}>
          {EMPLOYER_LOGOS.map((logo) => (
            <Box component="div" key={logo.name} sx={classes.logoImage}>
              <img
                src={logo.logo}
                height={logo.height}
                width={logo.width}
                alt={`${logo.name} logo`}
              />
            </Box>
          ))}
        </Box> */}

        <Box component="div" sx={classes.videoContainer}>
          <Box component="div" sx={classes.videoPlayer}>
            <ReactPlayer
              url={TRUSTED_VIDEO_URL}
              controls
              playing
              light
              width="100%"
              height="100%"
              playIcon={<IconPlayVideo />}
            />
          </Box>
        </Box>
      </Container>
      <WaveDivider
        position={POSITION.ABSOLUTE}
        backgroundColor="transparent"
        shapeFill={careTheme.palette.care.white}
        flipHorizontal={isMobile || isTablet}
      />
    </Box>
  );
};

export default TrustedSection;
